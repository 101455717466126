import React from "react"
import FoodAppleIcon from "mdi-react/FoodAppleIcon"

import PillIcon from "mdi-react/PillIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import financial from "../../images/components/financial.jpg"
import financialBody1 from "../../images/components/financial-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./financial-literacy-and-asset-security-program-for-family-members.scss"

var FinancialLiteracyAndAssetSecurityProgramForFamilyMembers = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="financial-literacy-and-asset-security-program-for-family-members-page">
      <Banner
        src={financial}
        title="Financial Literacy and Asset Security Program for Family Members"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="center"
      />
      <PageBody className="page">
        <BorderTitle>
          Financial Literacy and Asset Security Program for Family Members
        </BorderTitle>
        <div className="body">
          <img className="left" src={financialBody1} alt="" /><p>Many times, seniors receive some assistance from their family members; this support is often monetary.  In the communities that we are targeting, however, this can cause a severe strain on the senior and their family.  This part of the Health-Smart Holistic Health for Seniors aims to address the social support that senior participants have by teaching their supporting family members tenets of financial literacy.</p><p>Additionally, the program aims to help these family members save up at least $600 worth of emergency funds so that, in the event an unexpected expense arises (such as a sudden medical bill), they are prepared.</p><p>In order to do this, our team has partnered with the Jacksonville Urban League.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/assistance-with-enrolling-in-health-insurance-program-of-choice"
            className="first"
            icon={PillIcon}
            title="Assistance with Enrolling in Health Insurance Program of Choice"
            />
          <ComponentNavigator
            next
            link="/components-year-two/church-based-food-pantry-program"
            icon={FoodAppleIcon}
            title="Church-Based Food Pantry Program"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default FinancialLiteracyAndAssetSecurityProgramForFamilyMembers
